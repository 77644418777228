import Loguin from './Loguin';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Formulario from './Formulario';
import Edit from './Edit';
import Test from './test.jsx'

import MuestraLegajo from './muestraLegajo';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Test/ >}/>
          <Route path='/new' element={<Formulario />}/>
          <Route path='/inscripto' element={<Edit />}/>
          <Route path='/imprime' element={<MuestraLegajo />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
