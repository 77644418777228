import {io} from 'socket.io-client';
import {useEffect,useState} from 'react';

const socket=io('apps.isepsantafe.edu.ar')



function Loguin() {
    const [mensaje,setMensaje]=useState('')

    const handleSubmit=(e)=>{
e.preventDefault();try {
    socket.emit('mensaje',mensaje)//envio evento
} catch (error) {
    
}

    }

    useEffect(() => {
        socket.on('mensaje', mensaje=>setMensaje(mensaje))//escucho evento
    return()=>{
        socket.off('mensaje', mensaje=>setMensaje(mensaje))//me desuscribo al evento
    }}, [mensaje]);



return(
    <>
    <form onSubmit={handleSubmit} >
        <input type="text" onChange={e=>setMensaje(e.target.value)} />
<button>Enviar</button>
    </form>
    {mensaje}
    </>
)
}
export default Loguin